import { MouseEvent as ReactMouseEvent, ReactChild } from "react";
import { Typography, Breadcrumbs, Link, IconButton, makeStyles } from "@material-ui/core";
import { ArrowBack, SaveOutlined } from "@material-ui/icons";
import clsx from "clsx";

import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    fontFamily: "Lato",
    letterSpacing: 0.08,
    fontWeight: 400,
    fontSize: 16,
    textTransform: "uppercase",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  root: {},
  backButton: {
    color: theme.palette.primary.contrastText,
    marginRight: 16,
  },
  saveButton: {
    color: theme.palette.primary.contrastText,
    marginLeft: 16,
  },
  nav: {
    flex: 2,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
  prefixButtons: {
    flex: 1,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "flex-start",
  },
  postfixButtons: {
    flex: 1,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "flex-end",
    flexShrink: 1,
  },
}));

export interface HeaderLinkProp {
  href?: string;
  label: string;
}

type LinkEvent = ReactMouseEvent<HTMLAnchorElement, MouseEvent>;
type ButtonEvent = ReactMouseEvent<HTMLButtonElement, MouseEvent>;

export interface FormHeaderProps {
  className?: string;
  classes?: Record<keyof ReturnType<typeof useStyles>, string>;
  onClickBack?: (e: ButtonEvent) => void;
  onClickSave?: (e: ButtonEvent) => void;
  onClickLink?: (e: LinkEvent, link: HeaderLinkProp, index: number) => void;
  hasBack?: boolean;
  hasSave?: boolean;
  links: HeaderLinkProp[];
  title: string;
  renderPrefix?: () => ReactChild;
  renderPostfix?: () => ReactChild;
}

export default function FormHeader({
  className,
  onClickBack,
  onClickSave,
  onClickLink,
  hasBack,
  hasSave,
  links,
  title,
  renderPrefix,
  renderPostfix,
}: FormHeaderProps) {
  const classes = useStyles();
  return (
    <Header className={clsx(classes.root, className)}>
      <div className={classes.prefixButtons}>
        {(hasBack ?? true) && (
          <IconButton className={classes.backButton} onClick={onClickBack}>
            <ArrowBack />
          </IconButton>
        )}
        {renderPrefix && renderPrefix()}
      </div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator="//"
        className={classes.nav}
        maxItems={2}
        itemsBeforeCollapse={0}
        itemsAfterCollapse={2}
      >
        {links.map((link, index) => (
          <Link
            href={link.href}
            onClick={onClickLink && ((e: LinkEvent) => onClickLink(e, link, index))}
            key={link.href}
            className={classes.link}
            color="inherit"
          >
            {link.label}
          </Link>
        ))}
        <Typography color="inherit" className={classes.link}>
          {title}
        </Typography>
      </Breadcrumbs>
      <div className={classes.postfixButtons}>
        {renderPostfix && renderPostfix()}
        {(hasSave ?? true) && (
          <IconButton className={classes.saveButton} onClick={onClickSave}>
            <SaveOutlined />
          </IconButton>
        )}
      </div>
    </Header>
  );
}
