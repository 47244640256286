import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

export type SearchFieldProps = MuiTextFieldProps & {
  size?: "default" | "small";
};

const useStyles = makeStyles({
  input: (props: SearchFieldProps) =>
    props.size === "small"
      ? {
          paddingTop: 6.5,
          paddingBottom: 6.5,
        }
      : {},
});

const SearchField = (props: SearchFieldProps) => {
  const classes = useStyles(props);

  return (
    <MuiTextField
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputProps: {
          className: classes.input,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" disabled={props.disabled}>
              <Search color={props.disabled ? "disabled" : "primary"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default SearchField;
