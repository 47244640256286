import { Button as MuiButton, ButtonProps as MuiButtonProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface ButtonProps extends Omit<MuiButtonProps, "variant" | "color"> {
  mode?: "primary" | "secondary" | "warning";
}

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

const Button = ({ mode = "primary", ...props }: ButtonProps) => {
  const classes = useStyles(props);

  return (
    <MuiButton
      className={clsx(mode === "warning" && classes.warning)}
      size="small"
      variant={mode === "secondary" ? "outlined" : "contained"}
      color="primary"
      disableElevation
      {...props}
    />
  );
};

export default Button;
