import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@material-ui/core";
import clsx from "clsx";

export interface CheckboxProps {
  checked?: MuiCheckboxProps["checked"];
  onChange?: MuiCheckboxProps["onChange"];
  className?: string;
  label?: string;
  disabled?: boolean;
  CheckboxProps?: Partial<MuiCheckboxProps>;
  FormControlLabelProps?: Partial<MuiFormControlLabelProps> & { label: string };
}

const Checkbox = ({
  checked,
  onChange,
  label,
  className,
  disabled,
  CheckboxProps: _CheckboxProps,
  FormControlLabelProps,
}: CheckboxProps) => {
  if (!FormControlLabelProps && label !== undefined) {
    FormControlLabelProps = { label };
  } else if (FormControlLabelProps && label !== undefined) {
    FormControlLabelProps.label = label;
  }

  if (!_CheckboxProps) {
    _CheckboxProps = { checked, onChange };
  } else {
    _CheckboxProps.checked = checked;
    _CheckboxProps.onChange = onChange;
  }

  if (FormControlLabelProps) {
    FormControlLabelProps.disabled = disabled;
  }

  _CheckboxProps.disabled = disabled;

  if (FormControlLabelProps) {
    return (
      <FormControlLabel
        className={clsx(className)}
        control={<MuiCheckbox disableRipple color="primary" {..._CheckboxProps} />}
        {...FormControlLabelProps}
      />
    );
  }
  return (
    <MuiCheckbox className={clsx(className)} disableRipple color="primary" {..._CheckboxProps} />
  );
};

export default Checkbox;
