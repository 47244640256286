import {
  AppBar,
  Toolbar,
  AppBarProps,
  ToolbarProps,
  LinearProgress,
  makeStyles,
  AppBarClassKey,
} from "@material-ui/core";

import { colors } from "./theme";

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.policeBlue,
  },
  progress: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  toolbar: {
    alignItems: "stretch",
  },
});

export interface HeaderProps extends AppBarProps {
  classes?: Record<AppBarClassKey | keyof ReturnType<typeof useStyles>, string>;
  ToolbarProps?: ToolbarProps;
  isLoading?: boolean;
}

const Header = ({
  position = "fixed",
  children,
  ToolbarProps: _ToolbarProps,
  isLoading,
  ...props
}: HeaderProps) => {
  const { progress, toolbar, ...classes } = useStyles(props);

  return (
    <AppBar {...props} classes={classes} position={position}>
      <Toolbar {..._ToolbarProps} className={toolbar} disableGutters>
        {children}
      </Toolbar>
      {isLoading ? <LinearProgress className={progress} /> : null}
    </AppBar>
  );
};

export default Header;
