import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  InputLabel,
  InputLabelProps as MuiInputLabelProps,
  FormControl,
  FormControlProps as MuiFormControlProps,
  FormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

import clsx from "clsx";

export interface SelectOption {
  value: string | number;
}

export interface InjectedOptionProps {
  option: SelectOption;
  index: number;
}

export interface SelectProps {
  label?: string;
  items: SelectOption[];
  value: MuiSelectProps["value"];
  onChange: MuiSelectProps["onChange"];
  onBlur?: MuiSelectProps["onBlur"];
  onFocus?: MuiSelectProps["onFocus"];
  helperText?: string;
  error?: boolean;
  children: (props: InjectedOptionProps) => JSX.Element | JSX.Element[] | string;
  className?: string;
  SelectProps?: Partial<MuiSelectProps>;
  FormControlProps?: Partial<MuiFormControlProps>;
  InputLabelProps?: Partial<MuiInputLabelProps>;
  FormHelperTextProps?: Partial<MuiFormHelperTextProps>;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Select = ({
  label,
  items,
  helperText,
  children,
  className,
  value,
  error,
  onChange,
  onBlur,
  onFocus,
  SelectProps: _SelectProps,
  FormControlProps,
  InputLabelProps,
  FormHelperTextProps,
}: SelectProps) => {
  const classes = useStyles();
  const labelId = _SelectProps?.labelId;

  if (typeof error === "boolean") {
    if (!FormHelperTextProps) {
      FormHelperTextProps = {};
    }
    if (!InputLabelProps) {
      InputLabelProps = {};
    }
    FormHelperTextProps.error = error;
    InputLabelProps.error = error;
  }

  return (
    <FormControl
      variant="filled"
      className={clsx(classes.formControl, className)}
      {...FormControlProps}
    >
      {label && (
        <InputLabel id={labelId} shrink {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {..._SelectProps}
      >
        {items.map((option, index) => (
          <MenuItem value={option.value} key={option.value}>
            {children({
              option,
              index,
            })}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
